import { useBreakpoints } from '@vueuse/core'

const breakpointsTailwind: {
    md: number;
    xxl: number;
} = {
    md: 768,
    xxl: 2200,
};
export const isMobile = useBreakpoints(breakpointsTailwind).smaller('md');
export const isXxl = useBreakpoints(breakpointsTailwind).greaterOrEqual('xxl');
